import React, {useState, useEffect, useMemo} from 'react';
import 'leaflet/dist/leaflet.css';
import {MapContainer, redIcon, blueIcon} from './MapyCz';
import L from 'leaflet';


const fixedPin = blueIcon;
const defaultPin = redIcon;
const defaultCenter = {lat: 50.736111, lng: 15.740278};

function calculateCenter(coordinates = []) {
	if (!Array.isArray(coordinates) || coordinates.length === 0) {
		return defaultCenter;
	}

	return L.latLngBounds(
		coordinates
			.filter(coord => coord.lat && coord.lng)
			.map(coord => [coord.lat, coord.lng]),
	);
}

export default function AppList({lat, lng}) {
	const [items, setItems] = useState([
		{
			latitude: lat,
			longitude: lng,
			icon: defaultPin,
			lat,
			lng
		}
	]);
	const [map, setMap] = useState(null);
	const apiKey = 'IyNQOCIxcEvQ1yDmXM1_40JjUBhJ8GMjETpodkKUK6M';

	useEffect(() => {
		if (map) {
			if (items.length > 0) {
				map.fitBounds(calculateCenter(items));
				map.setZoom(16);
			}
		}
	}, [map, items]);

	return useMemo(() => {
		return (
			<MapContainer
				zoom={15}
				center={defaultCenter}
				style={{minHeight: '150px', height: '100%', width: '100%'}}
				className="smap"
				scrollWheelZoom={false}
				setMap={setMap}
				map={map}
				markers={items}
				apiKey={apiKey}
			/>
		);
	}, [apiKey, map, setMap, items]);
}

