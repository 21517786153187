import React, {useState, useEffect, useMemo} from 'react';
import 'leaflet/dist/leaflet.css';
import {MapContainer, redIcon, blueIcon} from './MapyCz';
import L from 'leaflet';


const fixedPin = blueIcon;
const defaultPin = redIcon;
const defaultCenter = {lat: 50.736111, lng: 15.740278};

function calculateCenter(coordinates = []) {
	if (!Array.isArray(coordinates) || coordinates.length === 0) {
		return defaultCenter;
	}

	return L.latLngBounds(
		coordinates
			.filter(coord => coord.lat && coord.lng)
			.map(coord => [coord.lat, coord.lng]),
	);
}

export default function App({gpx}) {
	const [items, setItems] = useState(window.mapMarkers);
	const [map, setMap] = useState(null);
	const apiKey = 'IyNQOCIxcEvQ1yDmXM1_40JjUBhJ8GMjETpodkKUK6M';

	useEffect(() => {
		if (map) {
			if (items.length > 0) {
				map.fitBounds(calculateCenter(items));
			}
		}
	}, [map, gpx, items]);

	useEffect(() => {
		const regionCourtFilter = document.getElementById('filter-region-court');
		if (regionCourtFilter) {
			regionCourtFilter.addEventListener('change', function () {
				handleFiltering('region_court_name', this.value);
			})
		}

		const regionFilter = document.getElementById('filter-region');
		if (regionFilter) {
			regionFilter.addEventListener('change', function () {
				handleFiltering('region_name', this.value);
			})
		}
		const regionCity = document.getElementById('filter-city');
		if (regionCity) {
			regionCity.addEventListener('change', function () {
				handleFiltering('city', this.value);
			})
		}
	}, []);

	const markers = useMemo(() => {
		return items.map(item => ({
				id: item.id,
				latitude: item.lat,
				longitude: item.lng,
				icon: defaultPin,
				onClick: () => setSelectedItem(item.object_id),
				popup: `
         <a href=${item.permalink}>
            ${item.name}
          </a>
          <div>${item.formatted_address}</div>
        `,
			}),
		);
	}, [items]);

	const handleFiltering = (type, value) => {
		let filteredMarkers = items;
		if (value) {
			filteredMarkers = filteredMarkers.filter((item) => {
				return item[type] === value
			});
		}

		filteredMarkers.map(item => {
			return {
				id: item.id,
				latitude: item.lat,
				longitude: item.lng,
				icon: defaultPin,
				popup: `
         <a href=${item.permalink}>
            ${item.name}
          </a>
          <div>${item.formatted_address}</div>
        `,
			};
		});

		setItems(filteredMarkers);


		const elements = document.querySelectorAll('.contacts__city');

		elements.forEach(item => {
			item.style.display = 'block';
		});
		if (type === 'region_name' && value) {
			elements.forEach(item => {
				if (item.dataset.regionName !== value) {
					item.style.display = 'none';
				}
			});
		} else if (type === 'region_court_name' && value) {
			elements.forEach(item => {
				if (item.dataset.regionCourtName !== value) {
					item.style.display = 'none';
				}
			});
		} else if (type === 'city' && value) {
			elements.forEach(item => {
				if (item.dataset.city !== value) {
					item.style.display = 'none';
				}
			});
		}
	}

	return useMemo(() => {
		return (
			<MapContainer
				zoom={11}
				center={defaultCenter}
				style={{minHeight: '350px', height: '100%', width: '100%'}}
				className="smap"
				scrollWheelZoom={false}
				setMap={setMap}
				map={map}
				markers={markers}
				apiKey={apiKey}
			/>
		);
	}, [apiKey, map, setMap, items,markers]);
}

