import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export const redIcon = L.icon({
	iconUrl: 'https://api.mapy.cz/img/api/marker/drop-red.png',
	iconSize: [22, 31],
	iconAnchor: [11, 31],
});

export const blueIcon = L.icon({
	iconUrl: 'https://api.mapy.cz/img/api/marker/drop-blue.png',
	iconSize: [22, 31],
	iconAnchor: [11, 31],
});

export const yellowIcon = L.icon({
	iconUrl: 'https://api.mapy.cz/img/api/marker/drop-yellow.png',
	iconSize: [22, 31],
	iconAnchor: [11, 31],
});

export function MapContainer ({
								  map,
								  setMap,
								  center = { lat: 0, lng: 0 },
								  zoom = 13,
								  mapset = 'basic',
								  onZoomEnd,
								  onMoveEnd,
								  className,
								  style,
								  scrollWheelZoom,
								  markers,
								  apiKey,
							  }) {
	const mapRef = useRef();

	useEffect(() => {
		const map = new L.Map(mapRef.current, {
			center: new L.LatLng(center.lat, center.lng),
			zoom,
			scrollWheelZoom,
		});
		map.getContainer().focus = ()=>{}

		const LogoControl = L.Control.extend({
			options: { position: 'bottomleft' },
			onAdd: () => {
				const container = L.DomUtil.create('div');
				const link = L.DomUtil.create('a', '', container);
				link.setAttribute('href', 'http://mapy.cz/');
				link.setAttribute('target', '_blank');
				link.setAttribute('rel', 'noreferrer noopenner');
				link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" alt="Seznam.cz a.s." />';
				L.DomEvent.disableClickPropagation(link);
				return container;
			},
		});

		new LogoControl().addTo(map);

		L.tileLayer(`https://api.mapy.cz/v1/maptiles/${mapset}/256/{z}/{x}/{y}?apikey=${apiKey}`, {
			minZoom: 0,
			maxZoom: 19,
			attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
		}).addTo(map);

		setMap(map);

		return () => {
			map.remove();
			setMap(null);
		};
	}, [mapset, apiKey, scrollWheelZoom]);

	useEffect(() => {
		if (map && onZoomEnd) {
			map.on('zoomend', onZoomEnd);
		}
		return () => {
			if (map && onZoomEnd) {
				map.off('zoomend', onZoomEnd);
			}
		};
	}, [map, onMoveEnd, onMoveEnd]);

	useEffect(() => {
		if (map && onMoveEnd) {
			map.on('moveend', onMoveEnd);
		}
		return () => {
			if (map && onMoveEnd) {
				map.off('moveend', onMoveEnd);
			}
		};
	}, [map, onMoveEnd, onMoveEnd]);

	useEffect(() => {
		if (map && Array.isArray(markers) && markers.length > 0) {
			markers.forEach(marker => {
				const markerInstance = L.marker(
					[marker.latitude, marker.longitude],
					{
						icon: marker.icon || redIcon,
						draggable: marker.draggable,
					},
				);
				if (marker.popup) {
					markerInstance.bindPopup(marker.popup);
				}
				map.addLayer(markerInstance);
				if (marker.onDragEnd) {
					markerInstance.on('dragend', marker.onDragEnd);
				}

			});
		}

		return () => {
			if (map) {
				map.eachLayer(layer => {
					if (layer instanceof L.Marker) {
						layer.remove();
					}
				});
			}
		};
	}, [markers, map]);

	return (
		<div ref={mapRef} className={className} style={style} />
	);
}
