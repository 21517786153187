import App from './components/App';
import {createRoot} from 'react-dom/client';
import React from 'react';
import AppList from "./components/AppList";
import AppSingle from "./components/AppSingle";

document.querySelectorAll('[data-app="contacts-map"]').forEach(function (node) {
	const root = createRoot(node);
//{...JSON.parse(node.dataset.attributes)}
	root.render(
		<App/>
	);

});

document.querySelectorAll('[data-app="contacts-map-list"]').forEach(function (node) {
	const root = createRoot(node);
//{...JSON.parse(node.dataset.attributes)}
	root.render(
		<AppList lat={node.dataset.lat} lng={node.dataset.lng}/>
	);

});

document.querySelectorAll('[data-app="contacts-map-single"]').forEach(function (node) {
	const root = createRoot(node);
//{...JSON.parse(node.dataset.attributes)}
	root.render(
		<AppSingle lat={node.dataset.lat} lng={node.dataset.lng}/>
	);

});
